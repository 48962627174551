
export type primitive = string | boolean | number;

const MAX_DEPTH = 5;

export function flatten(obj: Record<string, any>, iteration?: number, base?: string): Record<string, primitive> {
    iteration = iteration ? iteration + 1 : 1;
    if (iteration > MAX_DEPTH) {
        return {};
    }
    let returnObject = {};
    Object.entries(obj).forEach(([key, value]) => {
        const newKey = [base, key]
            .filter((key) => !!key)
            .join('.');

        if (!value) {
            return;
        }

        if (typeof value === 'object') {
            returnObject = {
                ...returnObject,
                ...flatten(value, iteration, newKey),
            };
        } else {
            returnObject[newKey] = value;
        }

    });

    return returnObject;
}
