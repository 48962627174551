import { ActionService } from '../../services/actions/ActionService';
import { DefaultActionData } from '@wsb_dev/datafi-shared/lib/types/ActionTypes';
import { bindable, containerless, inject } from 'aurelia-framework';

@containerless
@inject(
    ActionService,
)
export class ActionButton {
    @bindable icon: string;
    @bindable label: string;
    @bindable pending: boolean;

    @bindable action: DefaultActionData;
    @bindable target: string[];

    constructor(private actionService: ActionService) { }

    publishAction(event: Event) {
        event.stopPropagation();
        if (this.pending) {
            return;
        }
        this.pending = true;
        this.actionService.handleAction({
            ...this.action,
            target: this.target,
        }).finally(() => {
            this.pending = false;
        });
    }
}
