import { Field, ProgramAssetType } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject } from 'aurelia-dependency-injection';
import { DialogService } from 'aurelia-dialog';
import { Filter } from '@wsb_dev/datafi-shared/lib/types/Filter';
import { FilterDialog } from '../../../components/filter-dialog/filter-dialog';
import { ProjectBreadcrumbsService } from '../../../services/assets/ProjectBreadcrumbs';
import { ActiveProgram } from '../../../services/util/ActiveProgram';
import { ensureNumber } from '../../../util/numbers/ensureNumber';
import { bindable } from 'aurelia-framework';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';
import { MapAdaptor } from '../../../services/util/MapAdaptor';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { AppConfig } from '../../../services/util/AppConfig';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';

export interface AssetTypeParams {
    typeId?: string;
    projectId?: string;
}

@autoinject
export class AssetTable {
    params: Record<string, any>;
    assetType?: ProgramAssetType;
    fields: Field[];

    @bindable assetFilters: Filter[];
    @bindable paramsReset

    defaultFields: Field[] = getFields([
        { name: 'updatedAt', label: 'Edited', type: 'date' },
        { name: 'updatedBy', label: 'Edited By' },
        { name: 'type', label: 'Geometry' },
    ]);

    constructor(
        private api: DatafiProAPI,
        private program: ActiveProgram,
        private breadcrumbs: ProjectBreadcrumbsService,
        private dialogService: DialogService,
        private ma: MapAdaptor,
        private config: AppConfig,
    ) { }

    async activate(params: AssetTypeParams) {
        const typeId = ensureNumber(params.typeId) || undefined;
        const projectId = ensureNumber(params.projectId) || undefined;

        return this.program.load().then(async () => {
            const assetType = this.program.assetTypes?.find((type) => type.id === typeId);
            this.assetType = assetType;
            this.breadcrumbs.assetType = this.assetType;
            this.fields = this.getFields(this.assetType);
            this.params = {
                program_id: this.program.id,
                asset_type_id: typeId,
                project_id: projectId,
            };

            this.assetFilters = getFields([
                {
                    name: 'createdAt',
                    type: 'date',
                },
                {
                    name: 'modifiedAt',
                    type: 'date',
                },
                {
                    name: 'metadata',
                    type: 'object',
                    fields: this.assetType.assetSchema
                        .filter((f) => f.visibility?.filter),
                },
            ]);

            const layerId = `assets-${typeId}`;
            const layer: void|VectorLayer<VectorSource> = await this.ma.getVectorLayer({
                layerId,
                createLayer: true,
                updateLayer: true,
                layerOptions: {
                    visible: true,
                    dfpReady: true,
                    source: {
                        type: 'dfp/source/VectorDFP',
                        url: {
                            type: 'url/dfp/GeoJSON',
                            serviceType: 'programs-assets',
                            baseUrl: this.config.API_HOST,
                            programId: this.program.id,
                            typeId: typeId,
                        },
                    },
                },
            });

            layer.setVisible(true);
        });
    }

    getFields(assetType: ProgramAssetType): Field[] {
        const fields: Field[] = [
            ...this.defaultFields,
            {name: 'metadata', type: 'object', fields: assetType.assetSchema},
        ];

        return flattenFields(fields);
    }

    updateFilters() {
        let params = {};

        if (this.params.metadata) {
            params = {
                ...this.params,
                ...this.paramsReset,
            };
        } else {
            params = {
                ...this.params,
            };
        }

        return this.dialogService.open({
            viewModel: FilterDialog,
            model: {
                currentFilter: params,
                sourceObjects: [],
                filterProperties: this.assetFilters,
            },
        }).whenClosed(async (result) => {
            if (result.wasCancelled) {
                return;
            }

            this.paramsReset = result.output;

            this.params = {
                ...this.params,
                ...result.output,
            };

            const layer = await this.ma.getVectorLayerSource({
                layerId: `assets-${this.assetType.id}`,
            });

            const layerUrl: any = layer.getUrl();
            layerUrl.params.query = this.params;
            layer.refresh();

        });
    }
}
