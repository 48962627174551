import { User } from '@wsb_dev/datafi-shared/lib/types/Users';
import { TClientType, Token } from '@wsb_dev/datafi-shared/lib/types/Token';
import { autoinject } from 'aurelia-dependency-injection';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { AlertService } from '../../../services/util/Alert';
import { AppConfig } from '../../../services/util/AppConfig';

@autoinject
export class AdminGenerateToken {
  user: User;
  tokenType: TClientType;
  clients: string[] = []

  token: Partial<Token>;

  constructor(
    private api: DatafiProAPI,
    private config: AppConfig,
    private alerts: AlertService,
  ) {
      this.reset();
  }

  generateToken(user: User) {
      this.api.tokens.create({
          ...this.token,
          user_id: user?.id,
      }).then((res) => {
          this.token = res;
      }).catch((e) => {
          this.alerts.create({
              level: 'error',
              label: `Error creating token: ${e.message}`,
              dismissable: true,
          });
      });
  }

  reset(){
      this.token = {
          client_type: 'ip',
          clients: [],
          enabled: true,
          user_id: null,
      };
  }

}
