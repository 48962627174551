import { BaseModel } from '@wsb_dev/datafi-shared/lib/types/BaseModel';
import { Field } from '@wsb_dev/datafi-shared/lib/types/Field';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { titleize } from '@wsb_dev/datafi-shared/lib/util/strings/titleize';

export function getFieldsFromRow(row: BaseModel): Field[] {
    const fields = Object.keys(row)
        .map((key) => {

            const keyParts = key.split(/(\.|\/)/g);

            return {
                label: titleize(keyParts[keyParts.length - 1]),
                name: key,
            };
        });

    return getFields(fields);
}
