import {  autoinject } from 'aurelia-framework';
import { ActiveAssetsService } from '../../../services/assets/ActiveAssets';

@autoinject

export class AssetList {
    constructor(
        private assetService: ActiveAssetsService,
    ) {}
}
