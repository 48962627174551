import { autoinject } from 'aurelia-dependency-injection';
import { ActiveAssetsService } from '../../../services/assets/ActiveAssets';
import { ProgramAsset } from '@wsb_dev/datafi-shared/lib/types/ProgramAsset';
import { ActiveProgram } from '../../../services/util/ActiveProgram';
import { ProgramAssetType } from '@wsb_dev/datafi-shared/lib/types/ProgramAssetType';
import { Paginated, Service } from '@feathersjs/feathers';
import { LogManager, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import omit from 'lodash.omit';
import { debounced } from '../../../util/decorators/debounced';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';

export type AssetActionType = 'finish' | 'collect-new'

const log = LogManager.getLogger('dfp:assets');
@autoinject
export class AssetForm {
    @bindable assetTypes: ProgramAssetType[];
    @bindable selectedAssetType: ProgramAssetType | null;
    @bindable activeAsset: ProgramAsset;

    public legend: HTMLCanvasElement;

    assetCount: number;

    constructor(
        private api: DatafiProAPI,
        private activeAssetService: ActiveAssetsService,
        private program: ActiveProgram,
        private router: Router,
    ) {
    }

    activate(params: Record<string, string>) {
        this.program.load().then(() => {
            this.setAssetLegend();
            return this.api.programAssetTypes.find({
                query: { program_id: this.program.id },
            }).then((result) => {
                this.assetTypes = (result as Paginated<ProgramAssetType>).data;
            });
        }).then(() => {
            const assetId = parseInt(params.id);

            this.activeAssetService.get(assetId)
                .catch(() => {
                    this.api.programAssets.get(assetId);
                })
                .then((result) => {
                    /* handle routing result */
                    if (result) {
                        const assetForm = this.assetTypes.find((types) => {
                            if (result.asset_type_id === types.id) {
                                return types;
                            }
                        });
                        this.activeAsset = result;
                        this.selectedAssetType = assetForm;
                    }
                })
                .catch((e) => {
                    log.debug(e, 'something went wrong');
                });
        });
    }

    async assignAsset() {
        this.activeAsset = await this.activeAssetService.create({
            asset_type_id: this.selectedAssetType.id,
            program_id: this.program.id,
            type: this.selectedAssetType.type,
            geom: null,
            metadata: {
                id_title: this.selectedAssetType.title,
            },
        });
    }

    finishAssetOptions(finish: AssetActionType) {

        if (finish === 'finish') {
            this.api.programAssets.create(omit(this.activeAsset, ['id'])).then(() => {
                this.activeAssetService.remove(this.activeAsset.id);
                this.selectedAssetType = null;
                this.router.navigateBack();
            });
        } else if (finish === 'collect-new') {
            this.api.programAssets.create(omit(this.activeAsset, ['id'])).then(() => {
                this.activeAssetService.remove(this.activeAsset.id);
            });

            this.selectedAssetType = null;
            this.setAssetLegend();
        } else {
            this.selectedAssetType = null;
            this.setAssetLegend();
        }
    }

    deleteAsset() {
        delete this.activeAsset.position;
        this.activeAssetService.remove(this.activeAsset.id);
        this.selectedAssetType = null;
        this.router.navigateBack();
    }

    @debounced(200)
    setAssetLegend() {
        let canvas;
        let body;
        let context;
        let centerX;
        let centerY;
        let radius;

        if (!this.selectedAssetType) {
            this.assetTypes.forEach((assetList) => {
                switch (assetList.type) {
                case 'point':
                    canvas = document.createElement('canvas');
                    canvas.id = 'canvas' + assetList.id;
                    canvas.width = 25;
                    canvas.height = 25;

                    body = document.getElementById(assetList.id.toString());
                    body.appendChild(canvas);

                    context = canvas.getContext('2d');
                    centerX = canvas.width / 2;
                    centerY = canvas.height / 2;
                    radius = 10;

                    context.beginPath();
                    context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
                    context.fillStyle = '#a9a19c';
                    context.fill();
                    context.lineWidth = 1;
                    context.strokeStyle = '#6c6864';
                    context.stroke();
                    context.closePath();
                    break;

                case 'line':
                    canvas = document.createElement('canvas');
                    canvas.id = 'canvas' + assetList.id;
                    canvas.width = 25;
                    canvas.height = 25;

                    body = document.getElementById(assetList.id.toString());
                    body.appendChild(canvas);

                    context = canvas.getContext('2d');
                    context.beginPath();
                    context.lineWidth = 6;
                    context.strokeStyle = '#6c6864';
                    context.moveTo(5, 5);
                    context.lineTo(20, 20);
                    context.stroke();
                    context.closePath();
                    break;

                case 'polygon':
                    canvas = document.createElement('canvas');
                    canvas.id = 'canvas' + assetList.id;
                    canvas.width = 25;
                    canvas.height = 25;

                    body = document.getElementById(assetList.id.toString());
                    body.appendChild(canvas);

                    context = canvas.getContext('2d');
                    context.beginPath();
                    context.rect(3, 3, 20, 20);
                    context.fillStyle = '#a9a19c';
                    context.fill();
                    context.lineWidth = 1;
                    context.strokeStyle = '#6c6864';
                    context.stroke();
                    context.closePath();
                    break;
                }
            });
        }
    }
}
