import { ProgramAsset, ProgramAssetType } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { ActiveProgram } from '../../../services/util/ActiveProgram';
import { ProjectBreadcrumbsService } from '../../../services/assets/ProjectBreadcrumbs';
import { MapAdaptor } from '../../../services/util/MapAdaptor';
import { subscribe } from '../../../util/decorators/subscribe';
import { AppConfig } from '../../../services/util/AppConfig';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

@autoinject
@subscribe({
    events: [
        {eventEmitter: 'api.programAssets', event: 'patched', fn: 'onAssetPatched'},
        {eventEmitter: 'api.programAssets', event: 'created', fn: 'onAssetCreated'},
        {eventEmitter: 'ma', event: MapAdaptor.EVENT_MAP_ATTACHED, fn: 'updateMap'},
    ],
})
export class AssetTypeList {
    projectId?: number;
    assets: ProgramAsset[] = [];

    constructor(
        private activeProgram: ActiveProgram,
        private router: Router,
        private breadcrumbs: ProjectBreadcrumbsService,
        private ma: MapAdaptor,
        private config: AppConfig,
    ) { }

    onAssetPatched(asset: ProgramAsset): void{
        const assetToUpdate = this.assets.find((a) => a.id === asset.id);
        Object.assign(assetToUpdate, asset);
    }

    onAssetCreated(asset: ProgramAsset): void{
        this.assets.push(asset);
    }

    async attached() : Promise<void> {
        await this.activeProgram.load();
        this.updateMap();
    }

    async updateMap(){
        if(!this.ma.map){
            return;
        }
        this.activeProgram.assetTypes?.forEach(async (type) => {

            const layerId = `assets-${type.id}`;
            const layer: void|VectorLayer<VectorSource> = await this.ma.getVectorLayer({
                layerId,
                createLayer: true,
                updateLayer: true,
                layerOptions: {
                    dfpReady: true,
                    visible: true,
                    source: {
                        type: 'dfp/source/VectorDFP',
                        url: {
                            type: 'url/dfp/GeoJSON',
                            serviceType: 'programs-assets',
                            baseUrl: this.config.API_HOST,
                            programId: this.activeProgram.id,
                            typeId: type.id,
                        },
                    },
                },
            });

            type.__visible = layer.getVisible();
        });
    }

    navigate(assetType: ProgramAssetType): void {
        this.breadcrumbs.assetType = assetType;
        this.router.navigateToRoute(this.projectId ? 'project-assets-table' : 'assets-table', { typeId: assetType.id, projectId: this.projectId });
    }

    async toggleVisible(assetType: ProgramAssetType): Promise<void> {
        assetType.__visible = !assetType.__visible;
        const layer = await this.ma.getVectorLayer({
            layerId: `assets-${assetType.id}`,
        });

        layer.setVisible(assetType.__visible);
    }
}
