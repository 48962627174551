import { bindable, autoinject } from 'aurelia-framework';
import { Map } from 'ol';
import { MapAdaptor } from '../../services/util/MapAdaptor';
import { defaultMap } from '../projects/data/defaultMap';
import { Events } from '@wsb_dev/datafi-shared/lib/types/Events';
import { ActiveProgram } from '../../services/util/ActiveProgram';
import { ActiveAssetsService } from '../../services/assets/ActiveAssets';
import { Router, RouterConfiguration, RouteConfig, AppRouter } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { NavigationInstruction, RouterEvent } from 'aurelia-router';
import { AssetGeometryType, ProgramAsset } from '@wsb_dev/datafi-shared/lib/types/ProgramAsset';
import { ProgramAssetType } from '@wsb_dev/datafi-shared/lib/types/ProgramAssetType';
import { GeolocationService } from '../../services/util/Geolocation';
import { GeoJSONService } from '../../services/util/GeoJSON';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { subscribe } from '../../util/decorators/subscribe';

@subscribe({
    events: [
        { event: Events.OL_MAP_CREATED, eventEmitter: 'ea', fn: 'setupMap'},
        { event: RouterEvent.Complete, eventEmitter: 'ea', fn: 'handleRouteComplete'},
    ],
})
@autoinject
export class Assets {
    @bindable assets: ProgramAssetType[];
    @bindable collectedAsset: ProgramAssetType;

    @bindable activeAsset: ProgramAsset;

    navigationId: number;
    assetCount: number;

    mapOptions: any;
    map: Map;

    constructor(
        private geojson: GeoJSONService,
        private geolocation: GeolocationService,
        private mapAdaptor: MapAdaptor,
        private ea: EventAggregatorWrapper,
        private program: ActiveProgram,
        private activeAssetService: ActiveAssetsService,
        private router: Router,
    ) {
        this.router = router;

        this.program.load().then(() => {
            const mapConfig = this.program.configs?.find((config) => config.type === 'ol-map');
            this.mapOptions = mapConfig?.data || defaultMap;
        });

    }

    configureRouter(config: RouterConfiguration, router: AppRouter) {
        config.map([
            { name: 'asset-edit', route: ['', 'new', 'edit/:id'], moduleId: PLATFORM.moduleName('./asset-form/asset-form'), title: 'Edit Asset' },
            { name: 'asset-list', route: ['list'], moduleId: PLATFORM.moduleName('./asset-list/asset-list'), title: 'Asset List' },
        ]);
        this.router = router;
    }

    setupMap(map: Map) {
        this.map = map;
        this.mapAdaptor.map = this.map;
    }

    handleRouteComplete(event: { instruction: NavigationInstruction })  {
        this.navigationId = event.instruction.queryParams.part;
    }

    addPoint() {

        const active = this.activeAssetService.activeAsset as ProgramAsset;

        const points = [
            [
                this.geolocation.location.longitude,
                this.geolocation.location.latitude,
            ],
            ...(
                active.geom ? this.geojson.getPoints({
                    type: active.type as AssetGeometryType,
                    geom: active.geom,
                }) : []
            ),
        ];

        active.geom = this.geojson.create({
            type: active.type as AssetGeometryType,
            points: points as number[][],
        }) as any;

        this.mapAdaptor.updateLayer({
            layerId: 'asset-drawing',
            clear: true,
            create: this.activeAssetService.assets,
            createLayer: true,
        });
    }
}
