import { Paginated } from '@feathersjs/feathers';
import { User } from '@wsb_dev/datafi-shared/lib/types/Users';
import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';

@autoinject
export class UserSearchField {
    @bindable({mode: bindingMode.twoWay }) label: string
    @bindable({mode: bindingMode.twoWay }) user: User
    users: User[];

    constructor(private api: DatafiProAPI){}

  getUserOptions = (filter: string, value?: User) => {
      if(value){
          return this.users.filter((u) => u.id === value.id);
      }
      return this.api.users.find({
          query: {
              $or: [
                  {username: {$ilike: `%${filter}%`}},
                  {email: {$ilike: `%${filter}%`}},
                  {fullname: {$ilike: `%${filter}%`}},
              ],
          },
      }).then((result) => {
          this.users = (result as Paginated<User>).data;
          return this.users;
      });
  }

  getUserDisplayField(user: User){
      return user.fullname || user.username;
  }

}
